import "./index.css";
import React from "react";
import {
  MapLibreMap,
  MlNavigationTools,
  useMap,
} from "@mapcomponents/react-maplibre";
import ReactDOM from "react-dom";
import maplibregl from "maplibre-gl";
import HeadlessSlideOver from "../components/SlideIn";
import { BsSearch } from "react-icons/bs";
import { FaDirections } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SearchBox from "./SearchBox";
import { Context } from "../store";
import ContextMenu from "./ContextMenu";
import Categories from "../utils/Categories";
import MapPopup from "../components/MapPopup";
import _ from "lodash";
import MapLayer from "./MapLayers";
import { searchByCategory, searchNearBy } from "../utils/apis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const MapboxDirections = window.MapboxDirections;
const MapboxDraw = window.MapboxDraw;
const turf = window.turf;

var draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    line_string: true,
    polygon: true,
    trash: true,
  },
});
const directions = new MapboxDirections({
  // "pk.eyJ1IjoiZXhhbXBsZXMiLCJhIjoiY2p0MG01MXRqMW45cjQzb2R6b2ptc3J4MSJ9.zA2W0IkI0c6KaAhJfk9bWg",
  unit: "metric",
  profile: "powermap/driving",
});

const fullScreen = new maplibregl.FullscreenControl();

const layers = [
  { id: 1, name: "Powermap TH" },
  { id: 2, name: "Powermap EN" },
  { id: 3, name: "Powermap Dark" },
  { id: 4, name: "Powermap Gray" },
];

let defaultStyle =
  "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4";

function Map() {
  // console.log(localStorage, sessionStorage);
  const contextRef = React.useRef();
  const [selected, setSelected] = React.useState(layers[0]);
  const [state, setState] = React.useContext(Context);
  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const [map, setMap] = React.useState();
  const [marker, setMarker] = React.useState();
  const [popup, setPopup] = React.useState();
  const [markerArray, setMarkerArray] = React.useState();

  // console.log(state, "state");

  let mapWrapper = useMap({ mapId: "map_1" });
  const onRightClick = (event) => {
    // console.log("event", event);
    setState((state) => ({ ...state, context: event }));
    if (contextRef && contextRef.current) {
      contextRef.current.focus();
      contextRef.current.style.display = "flex";
      contextRef.current.style.top = event.point.y + "px";
      contextRef.current.style.left = event.point.x + "px";
    }
  };

  const handleSearchCategories = (item) => {
    // get search categories
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        let crd = pos.coords;
        // console.log("coord", crd);
        // if (!crd) {
        //   return toast.error("Location error", {
        //     position: "bottom-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }

        setState((state) => ({ ...state, results: [] }));
        let results = await searchByCategory({
          coor: crd.latitude + "," + crd.longitude,
          cate: item.id,
          dist: 10,
        });
        if (results.length !== 0) {
          let map = state.map;

          map.flyTo({
            center: [crd.longitude, crd.latitude],
            duration: 2000,
            zoom: 16,
          });
          setShowSearchBox(true);
          setState((state) => ({
            ...state,
            results,
            currentLocation: [crd.longitude, crd.latitude],
          }));
        }
      },
      // async () => {
      //   return toast.error("🌏  Location error", {
      //     position: "bottom-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // },
      options
    );
  };

  const contextClick = async (e) => {
    // console.log(e);
    const clicked = e.target.getAttribute("name");
    switch (clicked) {
      case "Nearby POIs":
        setState((state) => ({ ...state, results: [] }));
        let results = await searchNearBy({
          coor: state.context.lngLat.lat + "," + state.context.lngLat.lng,
          dist: 10,
        });
        if (results.length !== 0) {
          setShowSearchBox(true);
          setState((state) => ({ ...state, results }));
        }
        let map = state.map;
        map.flyTo({
          center: [state.context.lngLat.lng, state.context.lngLat.lat],
          duration: 2000,
          zoom: 16,
        });

      case "Clear":
        // clear map
        if (marker) {
          marker.remove();
        }
        contextRef.current.style.display = "none";
      case "Close":
        contextRef.current.style.display = "none";
    }
    if (contextRef && contextRef.current) {
      contextRef.current.style.display = "none";
    }
  };

  const updateArea = (e) => {
    var data = draw.getAll();
    var answer = document.getElementById("calculated-area");
    // console.log(data.features, turf);

    if (data.features.length > 0 && data.features.length === 1) {
      var length = turf.lineDistance(data, "meters");
      // restrict to area to 2 decimal points
      var rounded_length = Math.round(length * 100) / 100;
      answer.innerHTML =
        "<p className='font-jamjuree'><strong>" +
        rounded_length +
        "</strong></p><p className='font-jamjuree'>meters</p>";
    } else if (data.features.length > 0) {
      var area = turf.area(data);
      // restrict to area to 2 decimal points
      var rounded_area = Math.round(area * 100) / 100;
      answer.innerHTML =
        "<p className='font-jamjuree'><strong>" +
        rounded_area +
        "</strong></p><p className='font-jamjuree'>square meters</p>";
    } else {
      answer.innerHTML = "";
      if (e.type !== "draw.delete")
        alert("Use the draw tools to draw a polygon!");
    }
  };

  React.useEffect(() => {
    if (mapWrapper.mapIsReady && !map) {
      // console.log("new", mapWrapper);
      setState((state) => ({ ...state, map: mapWrapper.map }));
      setMap(mapWrapper.map);
    }
  });

  React.useEffect(() => {
    // console.log(map, state.showRouting);
    if (map) {
      if (!state.showRouting) {
        try {
          map.removeControl(directions);
        } catch (e) {
          // console.log(e);
        }
      } else {
        map.addControl(directions, "top-right");
        directions.on("step", (e) => {
          // console.log(e);
          map.flyTo({
            center: [e.step.longitude, e.step.latitude],
            duration: 2000,
            zoom: 16,
          });
        });

        // add default origin control
        if (state.currentLocation) {
          directions.setOrigin(state.currentLocation);
        }
        if (state.destination) {
          directions.setDestination(state.destination);
        }
      }
    }
  }, [state.showRouting]);

  React.useEffect(() => {
    if (state.destination) {
      directions.setDestination(state.destination);
    }
  }, [state.destination]);

  React.useEffect(() => {
    if (map) {
      if (selected.name !== "Powermap TH") {
        // remove draw first
        if (map.hasControl(draw)) {
          map.removeControl(draw);
        }
      }
      switch (selected.name) {
        case "Powermap TH":
          map.setStyle(
            "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
          );
          return;
        case "Powermap EN":
          map.setStyle(
            "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_en&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
          );
          return;
        case "Powermap Dark":
          map.setStyle(
            "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th_black&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
          );
          return;
        case "Powermap Gray":
          map.setStyle(
            "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th_gray&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
          );
          return;
        default:
          map.setStyle(
            "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4"
          );
          return;
      }
    }
  }, [selected]);

  React.useEffect(() => {
    if (map) {
      // add full screen
      if (!map.hasControl(fullScreen)) {
        map.addControl(fullScreen, "bottom-left");
      }

      // draw plugin
      if (!map.hasControl(draw) && selected.name == "Powermap TH") {
        map.addControl(draw, "bottom-left");
        map.on("draw.create", updateArea);
        map.on("draw.delete", updateArea);
        map.on("draw.update", updateArea);
      }

      // context menu
      map.on("contextmenu", onRightClick);
    }
  });

  React.useEffect(() => {
    // if (marker) {
    //   marker.remove();
    // }
    // if (popup) {
    //   popup.remove();
    // }
    if (state.selectedPOI && map) {
      // create the popup
      var create_popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: true,
        offset: 20,
      });
      const popupNode = document.createElement("div");
      ReactDOM.render(
        <MapPopup
          data={state.selectedPOI}
          state={state}
          setState={setState}
          key={"01"}
        />,
        popupNode
      );
      create_popup
        .setLngLat(state.selectedPOI.coordinates)
        .setDOMContent(popupNode)
        .addTo(map);

      // setPopup(create_popup);
    }
  }, [state.selectedPOI]);

  React.useEffect(() => {
    setState((state) => ({ ...state, showSearchBox }));
  }, [showSearchBox]);

  React.useEffect(() => {
    // console.log("test");
  }, [state.showRouting]);

  React.useEffect(() => {
    if (markerArray) {
      markerArray.map((item) => item.remove());
    }
    if (state.results && state.results.length > 0 && map) {
      let newMarkerArray = _.map(state.results, (each, index) => {
        let data = each._source;
        // create the popup
        var new_popup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: true,
          offset: 20,
        });
        const popupNode = document.createElement("div");

        ReactDOM.render(
          <MapPopup
            data={data}
            state={state}
            setState={setState}
            key={index}
          />,
          popupNode
        );
        new_popup.setLngLat(data.coordinates).setDOMContent(popupNode);
        // .addTo(map);

        // create DOM element for the marker
        var el = document.createElement("div");
        el.id = "marker_" + index;
        el.key = index;
        el.style.width = "30px";
        el.style.height = "38px";
        el.style.backgroundSize = "cover";
        el.style.backgroundImage =
          "url('./markers/" +
          _.filter(Categories, ["cat_new", data.new_cate])[0].new_type +
          ".png')";

        // console.log("crate marker", index, map, data);
        let newMarker = new maplibregl.Marker(el)
          .setLngLat(data.coordinates)
          .setPopup(new_popup)
          .addTo(map);
        return newMarker;
      });
      setMarkerArray(newMarkerArray);
    }
  }, [state.results]);

  return (
    <>
      {!showSearchBox && !state.showRouting && (
        <>
          <button
            className="fixed top-4 left-16 z-10 bg-white rounded-full p-3 shadow-md"
            onClick={() => setShowSearchBox(true)}
          >
            <BsSearch className="w-6 h-6" />
          </button>
          <div className="fixed flex top-2 space-x-2 left-32 z-10">
            {[
              {
                name_e: "Shopping",
                name_t: "ช้อปปิ้ง",
                id: "46",
                icon: "0116",
              },
              {
                name_e: "Restaurant",
                name_t: "อาหาร",
                id: "21",
                icon: "0312",
              },
              {
                name_e: "Gasoline",
                name_t: "น้ำมัน",
                id: "45",
                icon: "0712",
              },
            ].map((item, index) => (
              <button
                key={index}
                className={`flex pt-2 m-auto mt-2 hover:scale-110 transition ease-in-out delay-150`}
                onClick={() => {
                  handleSearchCategories(item);
                }}
              >
                <div className="flex space-x-2 px-3 py-1 rounded-lg text-white bg-purple-500 shadow-lg font-jamjuree">
                  <img
                    src={`./markers/${item.icon}.png`}
                    alt="poi-icon"
                    width={20}
                  />
                  <div className="hidden text-sm text-center my-auto sm:flex">
                    {item.name_e}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </>
      )}
      <button
        className={`fixed z-10 bg-white rounded-full p-3 shadow-md ${
          !state.showRouting ? "top-4 right-4" : "top-1 right-1"
        }`}
        onClick={() => {
          setState((state) => ({
            ...state,
            showRouting: !state.showRouting,
          }));
        }}
      >
        {!state.showRouting && <FaDirections className="w-6 h-6" />}
        {state.showRouting && <AiOutlineCloseCircle className="w-4 h-4" />}
      </button>
      <MapLibreMap
        mapId="map_1"
        options={{
          zoom: 8,
          style: defaultStyle,
          center: [100.514103509131, 13.773507201767668],
        }}
      />
      <MlNavigationTools />
      {!state.showRouting && (
        <MapLayer
          selected={selected}
          setSelected={setSelected}
          layer={layers}
        />
      )}

      {selected.name === "Powermap TH" && (
        <div
          id="calculated-area"
          className="fixed p-2 bg-white rounded-md shadow-lg bottom-2 left-28"
        ></div>
      )}
      <ContextMenu contextRef={contextRef} onClick={contextClick} />
      <div className="flex">
        {showSearchBox && (
          <HeadlessSlideOver
            open={showSearchBox}
            setOpen={setShowSearchBox}
            width="w-4/6 sm:w-80"
            layout="left"
          >
            <div className="flex flex-col h-screen">
              <SearchBox />
            </div>
          </HeadlessSlideOver>
        )}
      </div>
    </>
  );
}

export default Map;
