import React, { useState, useEffect, useRef } from "react";
import maplibregl from "maplibre-gl";
import ReactDOM from "react-dom";
import PopupDetails from "./PopupDetails";
import _ from "lodash";
import polyline from "@mapbox/polyline";
import "./index.css";
let popup;

let markerArray = [
  "Marker_1.png",
  "Marker_2.png",
  "Marker_3.png",
  "Marker_4.png",
  "Marker_5.png",
  "Marker_6.png",
  "Marker_7.png",
  "Marker_8.png",
  "Marker_9.png",
  "Marker_10.png",
  "Marker_11.png",
  "Marker_12.png",
  "Marker_13.png",
  "Marker_14.png",
  "Marker_15.png",
  "Marker_16.png",
  "Marker_17.png",
  "Marker_18.png",
  "Marker_19.png",
  "Marker_20.png",
];
function MapView({ map, state, setState, markerRef }) {
  const optionRef = React.useRef(state.options);
  const deleteCounterRef = React.useRef(0);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const mapRef = useRef();
  const updateMap = () => {
    if (map.current.getSource("places")) {
      // console.log(markerRef.current.data);
      map.current.getSource("places").setData(markerRef.current.data);
    }
  };
  const setMarkerState = (e) => {
    let stateMarkers = { ...markerRef.current };
    let newMarker;
    if (stateMarkers.data.features.length === 0) {
      newMarker = {
        type: "Feature",
        properties: {
          description: "Address here",
          icon: "truck-start-marker",
          index: stateMarkers.data.features.length,
          isStart: true,
          isEnd: false,
          isDrop: false,
          vehicle: "Veh. #1",
        },
        geometry: {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
      };
    } else {
      // console.log(stateMarkers.data.features.length, deleteCounterRef.current);
      newMarker = {
        type: "Feature",
        properties: {
          description: "Address here",
          icon:
            "Marker_" +
            parseInt(
              stateMarkers.data.features.length + deleteCounterRef.current
            ),
          index: stateMarkers.data.features.length + deleteCounterRef.current,
          isStart: false,
          isEnd: false,
          isDrop: true,
          vehicle: "N/A",
        },
        geometry: {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
      };
    }
    let newMarkers = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...stateMarkers.data.features, newMarker],
      },
    };
    markerRef.current = newMarkers;
    setState((state) => ({
      ...state,
      markerLayer: newMarkers,
    }));
  };

  // console.log(deleteCounterRef.current);

  const loadMap = async () => {
    map.current.on("load", () => {
      _.map(markerArray, (each) => {
        map.current.loadImage("./route_icons/" + each, function (error, image) {
          if (error) throw error;
          map.current.addImage(each.split(".")[0], image);
        });
      });

      map.current.loadImage(
        "./route_icons/truck_start.png",
        function (error, image) {
          if (error) throw error;
          map.current.addImage("truck-start-marker", image);
        }
      );
      map.current.loadImage(
        "./route_icons/truck_stop.png",
        function (error, image) {
          if (error) throw error;
          map.current.addImage("truck-stop-marker", image);
        }
      );
      map.current.loadImage("./route_icons/stop.png", function (error, image) {
        if (error) throw error;
        map.current.addImage("stop-marker", image);
        // Add a GeoJSON source with 15 points
        map.current.addSource("places", state.markerLayer);
        // Add a symbol layer
        map.current.addLayer({
          id: "places",
          type: "symbol",
          source: "places",
          layout: {
            "icon-image": ["get", "icon"],
            "icon-size": 0.3,
            // get the year from the source's "year" property
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [0, 1.25],
            "text-anchor": "top",
          },
        });
      });
    });
  };
  const handleChange = (selectedType, selected, index) => {
    // console.log("call", markerRef.current, selectedType, selected);
    if (markerRef.current.data.features.length > 0) {
      let cloneMarkerData = [...markerRef.current.data.features];
      let currentUpdate = _.map(cloneMarkerData, (item) => {
        if (item.properties.index === index) {
          let newMarker, isStart, isEnd, isDrop, vehicle;
          if (selectedType === "start") {
            if (selected.name !== "N/A") {
              newMarker = "truck-start-marker";
              isStart = true;
              isDrop = false;
              isEnd = false;
              vehicle = selected.name;
            } else {
              newMarker = "stop-marker";
              isStart = false;
              isDrop = true;
              isEnd = false;
              vehicle = "N/A";
            }
          }
          if (selectedType === "end") {
            if (selected.name !== "N/A") {
              newMarker = "truck-stop-marker"; // to make different icon
              isEnd = true;
              isDrop = false;
              isStart = false;
              vehicle = selected.name;
            } else {
              newMarker = "stop-marker";
              isEnd = false;
              isDrop = true;
              isStart = false;
              vehicle = "N/A";
            }
          }

          return {
            ...item,
            properties: {
              ...item.properties,
              icon: newMarker,
              isStart,
              isEnd,
              isDrop,
              vehicle,
            },
          };
        }
        return item;
      });
      markerRef.current = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: currentUpdate,
        },
      };
      setState((state) => ({
        ...state,
        markerLayer: markerRef.current,
      }));
      updateMap();
    }
  };

  const handleDelete = (index) => {
    // console.log("procedd to delete", index);
    let allMarkers = [...markerRef.current.data.features];
    deleteCounterRef.current = deleteCounterRef.current + 1;
    let filterMarkers = _.filter(
      allMarkers,
      (each) => each.properties.index !== index
    );
    markerRef.current = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: filterMarkers,
      },
    };
    setState((state) => ({
      ...state,
      markerLayer: markerRef.current,
    }));
    updateMap();
    // console.log(popup);
    popup.remove();
  };

  const renderRoute = async () => {
    let geoJson = [];
    _.map(state.routeOpt.routes, (each, index) => {
      let geometry = polyline.toGeoJSON(each.geometry);
      geoJson.push({
        type: "Feature",
        properties: {
          route_id: index + 1,
          color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        },
        geometry: geometry,
      });
    });
    if (!map.current.getSource("routeOpt")) {
      // Find the index of the first symbol layer in the map style
      map.current.addSource("routeOpt", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: geoJson,
        },
      });

      // add outline layer
      map.current.addLayer(
        {
          id: "my-route-layer-outline",
          type: "line",
          source: "routeOpt", // <= the same source id
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 10,
          },
        },
        "places"
      );

      // add route layer
      map.current.addLayer(
        {
          id: "my-route-layer",
          type: "line",
          source: "routeOpt", // <= the same source id
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#6084eb",
            "line-width": 8,
          },
        },
        "places"
      );
    } else {
      map.current.getSource("routeOpt").setData({
        type: "FeatureCollection",
        features: geoJson,
      });
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      map.current = new maplibregl.Map({
        container: mapRef.current,
        style:
          "https://maps.powermap.live/api/v2/map/vtile/styles?name=thailand_th_black&access_token=b378c575291af30a29f59919fd7e7e4c012d45c4",
        center: [100.514103509131, 13.773507201767668],
        zoom: 8,
      });

      map.current.on("click", "places", function (e) {
        e.clickOnLayer = true;
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;

        // console.log(state);

        setState((state) => ({
          ...state,
          updatedMarker: e.features[0].properties,
        }));

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        let popupNode = document.createElement("div");
        ReactDOM.render(
          <PopupDetails
            description={description}
            index={e.features[0].properties.index}
            data={markerRef.current}
            options={optionRef.current}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />,
          popupNode
        );
        popup = new maplibregl.Popup({
          closeOnClick: true,
          closeButton: false,
          offset: 10,
        })
          .setLngLat(coordinates)
          .setDOMContent(popupNode)
          .addTo(map.current);
      });

      map.current.on("click", async function (e) {
        // new jobs only click on map
        // console.log("here", e.clickOnLayer);
        if (e.clickOnLayer) {
          return;
        }
        if (popup && popup.isOpen()) {
          popup.remove();
          return;
        }
        setMarkerState(e);
        updateMap();
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.current.on("mouseenter", "places", function () {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.current.on("mouseleave", "places", function () {
        map.current.getCanvas().style.cursor = "";
      });

      map.current.addControl(new maplibregl.NavigationControl(), "top-right");
      loadMap(map.current);
    }
  }, []);

  React.useEffect(() => {
    optionRef.current = state.options;
  }, [state.options]);

  React.useEffect(() => {
    if (state.routeOpt) {
      renderRoute(state.routeOpt.routes, map);
    }
  }, [state.routeOpt]);

  return <div className="mapContainerRoute" ref={mapRef}></div>;
}

export default MapView;
