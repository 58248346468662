import React, { useState, useEffect } from "react";
import { MdAssistantDirection } from "react-icons/md";
import { getPOIsTH, getPOIsEN } from "../utils/apis";
import { Context } from "../store";
import _ from "lodash";
import Categories from "../utils/Categories";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { franc } from "franc";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export default function SearchBox() {
  const [state, setState] = React.useContext(Context);

  const handleChange = (e) => {
    if (e.target.value && e.target.value.length >= 2) {
      let language = franc(e.target.value, { minLength: 2 });
      // console.log("lang", language);
      setState((state) => ({ ...state, lang: language }));
      handleSearch(e.target.value);
    }
  };

  const handleClick = (data) => {
    // flight to location
    let map = state.map;
    map.flyTo({
      center: [data.geometry.coordinates[0], data.geometry.coordinates[1]],
      duration: 2000,
      zoom: 16,
    });
    setState((state) => ({ ...state, selectedPOI: data }));
  };

  const handleRouting = (data) => {
    // got to routing
    setState((state) => ({
      ...state,
      showRouting: true,
      destination: data.coordinates,
      showSearchBox: false,
    }));
  };
  const handleSearch = async (e) => {
    // get coordinates
    let map = state.map;
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        let crd = pos.coords;
        // console.log("coord", crd);
        if (!crd) {
          return alert("No Location found, search without reference");
        }
        setState((state) => ({
          ...state,
          results: [],
          currentLocation: [crd.longitude, crd.latitude],
        }));
        let results;
        if (state.lang === "tha") {
          results = await getPOIsTH(e, crd.latitude + "," + crd.longitude);
        } else {
          results = await getPOIsEN(e, crd.latitude + "," + crd.longitude);
        }
        setState((state) => ({ ...state, results }));
      },
      async () => {
        // toast.info("🌏 Location error, search without location reference", {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        let results;
        if (state.lang === "tha") {
          results = await getPOIsTH(e);
        } else {
          results = await getPOIsEN(e);
        }
        setState((state) => ({ ...state, results }));
      },
      options
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white">
      <div className="relative flex-shrink-0 px-4 py-4 text-gray-400 border-b dark:border-primary-darker dark:focus-within:text-light focus-within:text-gray-700">
        <span className="absolute inset-y-0 inline-flex items-center px-4">
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </span>
        <input
          onChange={handleChange}
          type="text"
          className="w-full py-2 pl-10 pr-4 border rounded-full dark:bg-dark dark:border-transparent dark:text-light focus:outline-none focus:ring"
          placeholder="Search..."
        />
      </div>

      <div className="flex-1 px-4 pt-3 space-y-2 overflow-y-hidden h hover:overflow-y-auto">
        {state.results &&
          state.results.map((each, index) => {
            // console.log("each", each);
            let data = each._source;
            return (
              <div
                key={index}
                className="flex bg-white shadow-lg p-2 rounded-lg justify-between transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:bg-sky-500 duration-300 cursor-pointer"
                onClick={() => handleClick(data)}
              >
                <div sr-only="icons" className="flex p-2 w-1/6 items-center">
                  <img
                    src={`./markers/${
                      _.filter(Categories, ["cat_new", data.new_cate])[0]
                        .new_type
                    }.png`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "./markers/default.png";
                    }}
                    alt="poi-icon"
                    width={30}
                  />
                </div>
                <div sr-only="poi_name" className="w-4/6 hover:text-white">
                  <div
                    sr-only="name"
                    className="flex flex-col font-semibold text-sm font-jamjuree"
                  >
                    {/* <div className="text-left">
                        {state.lang === "tha" ? data.name_t : data.name_e}
                      </div> */}
                    <div className="flex flex-col w-full font-jamjuree font-semibold text-sm">
                      {state.lang === "tha" ? data.name_t : data.name_e}{" "}
                      {each.sort
                        ? "[" +
                          each.sort[each.sort.length - 1].toFixed(2) +
                          "km]"
                        : ""}
                      {<hr className="w-full" />}
                    </div>
                  </div>
                  {state.lang !== "tha" && (
                    <div sr-only="admin_boundary" className="flex flex-col">
                      <div>
                        {data.address_e
                          ? data.address_e
                          : data.tambon_e +
                            ", " +
                            data.amphoe_e +
                            ", " +
                            data.province_e +
                            " " +
                            data.post_code}
                      </div>
                    </div>
                  )}
                  {state.lang === "tha" && (
                    <div sr-only="admin_boundary" className="flex flex-col">
                      <div>
                        {data.address_t
                          ? data.address_t
                          : " ต." +
                            data.tambon_t +
                            " อ." +
                            data.amphoe_t +
                            " จ." +
                            data.province_t +
                            " " +
                            data.post_code}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  sr-only="dierction_icons"
                  className="flex w-auto h-10 shadow-md p-2 justify-end items-center bg-blue-500 rounded-md"
                >
                  <button onClick={() => handleRouting(data)}>
                    <MdAssistantDirection className="w-6 h-6 text-white" />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
