import { backendUrl } from "../utils/backend";
export const login = async (identifier, password, isRememberMe) => {
  return await fetch(`${backendUrl}/api/auth/local`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      identifier,
      password,
    }),
  })
    .then(async (res) => {
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      } else {
        let result = await res.json();
        // console.log(result);
        if (isRememberMe) {
          localStorage.setItem("jwt", result.jwt);
          localStorage.setItem("username", result.user.username);
        } else {
          sessionStorage.setItem("jwt", result.jwt);
          sessionStorage.setItem("username", result.user.username);
        }
        return { status: true, jwt: result.jwt };
      }
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const signup = async (userData) => {
  return await fetch(`${backendUrl}/api/auth/local/register`, {
    body: JSON.stringify(userData),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const forgotpassword = async (email) => {
  return await fetch(`${backendUrl}/api/utils/provider-type?email=${email}`, {
    method: "GET",
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      let result = await res.text();
      // console.log(result);
      if (result === "local") {
        return await fetch(`${backendUrl}/api/auth/forgot-password`, {
          body: JSON.stringify({ email }),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (res) => {
            // console.log(res);
            // Handle success.
            if (res.status !== 200) {
              let result = await res.json();
              return { status: false, message: result.error.message };
            }
            return { status: true };
          })
          .catch((error) => {
            // Handle error.
            // console.log("An error occurred:", error.response);
          });
      } else if (result === "0") {
        return { status: false, message: `Email not found` };
      } else {
        return {
          status: false,
          message: `This is ${result} account. Can't reset password.`,
        };
      }
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};

export const resetpassword = async (password, code) => {
  return await fetch(`${backendUrl}/api/auth/reset-password`, {
    body: JSON.stringify({ password, passwordConfirmation: password, code }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      // console.log(res);
      // Handle success.
      if (res.status !== 200) {
        let result = await res.json();
        return { status: false, message: result.error.message };
      }
      return { status: true };
    })
    .catch((error) => {
      // Handle error.
      // console.log("An error occurred:", error.response);
    });
};
