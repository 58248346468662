let server = "https://maps.powermap.live/api/v2/map";
let token = "b378c575291af30a29f59919fd7e7e4c012d45c4";

export const getPOIsTH = async (term, coor) => {
  let results = await fetch(
    `${server}/address_th?q=${term}${
      coor ? "&coor=" + coor : ""
    }&access_token=${token}`
  ).then((res) => res.json());
  return results;
};
export const getPOIsEN = async (term, coor) => {
  let results = await fetch(
    `${server}/address_en?q=${term}${
      coor ? "&coor=" + coor : ""
    }&access_token=${token}`
  ).then((res) => res.json());
  return results;
};

export const searchByCategory = (searchData) => {
  let { coor, cate, dist } = searchData;
  return fetch(
    `${server}/cate?coor=${encodeURI(coor)}&type=${encodeURI(
      cate
    )}&access_token=${token}&dist=${dist}`
  ).then((res) => res.json());
};

export const searchNearBy = (searchData) => {
  let { coor, dist } = searchData;
  return fetch(
    `${server}/geo_search?coor=${encodeURI(
      coor
    )}&access_token=${token}&dist=${dist}`
  ).then((res) => res.json());
};
