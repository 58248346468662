import React, { useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiSettings4Fill, RiLogoutBoxLine } from "react-icons/ri";
import { FiBookOpen } from "react-icons/fi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineEngineering } from "react-icons/md";
import SlideIn from "./SlideIn";
import Settings from "./Settings";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ navigationData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const [currentRoute, setCurrentRoute] = useState(page || "maps");
  const [showSettings, setShowSettings] = useState(false);

  const openSettings = () => {
    // console.log("openSettings");
    setShowSettings(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");

    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("username");
    return navigate("/signin");
  };

  const renderIcon = useCallback((element, currentRoute) => {
    switch (element) {
      case "maps":
        return (
          <div className="">
            <FaMapMarkerAlt
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      case "optimize":
        return (
          <div className="">
            <MdOutlineEngineering
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
      case "docs":
        return (
          <div className="">
            <FiBookOpen
              className={`${
                element === currentRoute && "text-gray-500"
              } w-6 h-6`}
            />
          </div>
        );
    }
  }, []);

  React.useEffect(() => {
    setShowSettings(false);
  }, [currentRoute]);

  return (
    <nav className="fixed left-0 top-0 bottom-0 shadow-lg z-50 w-14 bg-sidebar flex flex-col h-screen justify-between items-center py-6">
      <span className="text-3xl text-green-400 p-1">
        <img src="./markers/powermap.png" alt="logo" className="w-10 h-10" />
      </span>
      <ul className="flex flex-col items-center w-full">
        {navigationData.map((element, index) => (
          <Link key={index} to={`/${element}`}>
            <li
              className={`text-white hover:text-gray-800 text-xl p-2 m-2 cursor-pointer ${
                currentRoute === element &&
                "p-2 rounded-md bg-white text-black shadow-lg hover:text-gray-100"
              }`}
              onClick={() => {
                setCurrentRoute(element, currentRoute);
              }}
            >
              {renderIcon(element)}
              <span
                className={`absolute w-auto min-w-max left-16 text-base font-medium hidden ${
                  currentRoute === element && "hover:inline-flex"
                }`}
              >
                {element}
              </span>
            </li>
          </Link>
        ))}
      </ul>
      <div className="flex flex-col justify-between items-center">
        {/* <div className="w-10 h-10 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-xl flex items-center justify-center text-gray-800 text-lg relative mb-4">
          <span className="h-5 w-5 flex justify-center items-center text-white absolute -top-1 -right-1 bg-red-500 text-xs rounded-full">
            24
          </span>
          <FaRegBell />
        </div> */}
        <span className="text-3xl text-gray-200 hover:text-gray-800 cursor-pointer mb-3">
          <button onClick={openSettings} className="">
            <RiSettings4Fill />
          </button>
        </span>
        <span className="text-3xl text-gray-200 hover:text-gray-800 cursor-pointer">
          <button onClick={handleLogout} className="">
            <RiLogoutBoxLine />
          </button>
        </span>
        {showSettings && (
          <SlideIn
            open={showSettings}
            setOpen={setShowSettings}
            width="w-4/5 sm:w-80"
            layout="right"
          >
            <div className="flex flex-col h-screen bg-white z-50">
              <Settings />
            </div>
          </SlideIn>
        )}
      </div>
    </nav>
  );
};

export default Sidebar;
