import React from "react";
import { login } from "../utils";
import { toast } from "react-toastify";
import { MdFace, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { backendUrl } from "../utils/backend";

function Login({ setJwt }) {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isRememberMe, setRememberMe] = React.useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleLogin = async () => {
    if (!username || !password) {
      toast.info(`You must provide a username and password`);
    } else {
      let loginResponse = await login(username, password, isRememberMe);
      // console.log(loginResponse);
      if (loginResponse.status) {
        toast.success("Login successful");
        setJwt(loginResponse.jwt);
        navigate("/");
      } else {
        return toast.error(loginResponse.message);
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <div className="bg-white dark:bg-black">
      <div className="flex justify-center h-screen">
        <div
          className="hidden bg-cover lg:block lg:w-2/3"
          style={{
            backgroundImage: "url(./bg.png)",
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
            <div>
              <h2 className="text-4xl font-bold text-white">POWERMAP</h2>

              <p className="max-w-xl mt-3 text-gray-300">
                Bring you anywhere with most reliable data
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                POWERMAP
              </h2>

              <p className="mt-3 text-gray-500 dark:text-gray-300">
                Sign in to access your account
              </p>
            </div>

            <div className="mt-8">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                >
                  Email / Username
                </label>
                <div className="relative block">
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={(e) => onKeyDown(e)}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email / Username"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                  <span className="absolute inset-y-1 right-2 items-center text-gray-200">
                    <MdFace size={32} />
                  </span>
                </div>
              </div>

              <div className="my-6">
                <label
                  htmlFor="password"
                  className="text-sm text-gray-600 dark:text-gray-200"
                >
                  Password
                </label>
                <div className="relative block">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => onKeyDown(e)}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                  <span className="absolute inset-y-1 right-2 items-center text-gray-200">
                    {showPassword ? (
                      <MdVisibility
                        onClick={() => togglePassword()}
                        size={32}
                      />
                    ) : (
                      <MdVisibilityOff
                        onClick={() => togglePassword()}
                        size={32}
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                    className="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm text-gray-800 dark:text-gray-100"
                  >
                    Remember me
                  </label>
                </div>
                <button
                  onClick={handleForgotPassword}
                  className="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
                >
                  Forgot password?
                </button>
              </div>
              <div className="mt-6">
                <button
                  onClick={handleLogin}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                >
                  Sign in
                </button>
              </div>
              <p className="mt-6 text-sm text-center text-gray-400">
                Don&#x27;t have an account yet?{" "}
                <button
                  onClick={handleSignup}
                  className="text-blue-500 focus:outline-none focus:underline hover:underline"
                >
                  Sign up
                </button>
                .
              </p>
            </div>
            <div className="flex items-center justify-center space-x-2 my-5">
              <span className="h-px w-16 bg-gray-200"></span>
              <span className="text-gray-300 font-normal">
                or continue with
              </span>
              <span className="h-px w-16 bg-gray-200"></span>
            </div>
            <div className="flex">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <a
                  href={`${backendUrl}/api/connect/google`}
                  className="w-full flex items-center justify-center bg-red-500  hover:bg-red-400 text-gray-100 p-3  rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                >
                  Sign in with
                  <svg
                    className="w-4 ml-2"
                    fill="#fff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.99 13.9v-3.72h9.36c.14.63.25 1.22.25 2.05 0 5.71-3.83 9.77-9.6 9.77-5.52 0-10-4.48-10-10S6.48 2 12 2c2.7 0 4.96.99 6.69 2.61l-2.84 2.76c-.72-.68-1.98-1.48-3.85-1.48-3.31 0-6.01 2.75-6.01 6.12s2.7 6.12 6.01 6.12c3.83 0 5.24-2.65 5.5-4.22h-5.51v-.01Z" />
                  </svg>
                </a>
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <a
                  href={`${backendUrl}/api/connect/facebook`}
                  className="w-full flex items-center justify-center bg-blue-600  hover:bg-blue-500 text-gray-100 p-3  rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                >
                  Sign in with
                  <svg
                    className="w-4 ml-2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M9.945 22v-8.834H7V9.485h2.945V6.54c0-3.043 1.926-4.54 4.64-4.54 1.3 0 2.418.097 2.744.14v3.18h-1.883c-1.476 0-1.82.703-1.82 1.732v2.433h3.68l-.736 3.68h-2.944L13.685 22"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
