import React, { useState, useEffect } from "react";
import Categories from "../utils/Categories";
import { MdAssistantDirection } from "react-icons/md";
import { AiOutlineCopy } from "react-icons/ai";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MapPopup({ data, state, setState, myKey }) {
  const handleRouting = (data) => {
    // console.log(data);
    // got to routing
    setState((state) => ({
      ...state,
      showRouting: true,
      destination: data.coordinates,
      showSearchBox: false,
    }));
  };

  if (data) {
    return (
      <div
        key={myKey}
        className="flex bg-white shadow-lg p-2 rounded-lg justify-between transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:bg-blue-700 duration-300"
      >
        <div sr-only="icons" className="flex p-2 w-1/6 items-center mr-2">
          <img
            src={`./markers/${
              _.filter(Categories, ["cat_new", data.new_cate])[0].new_type
            }.png`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "./markers/default.png";
            }}
            alt="poi-icon"
            width={30}
          />
        </div>
        <div
          sr-only="poi_name"
          className="w-5/6 hover:text-white justify-start"
        >
          <div className="flex-col">
            <div
              sr-only="name"
              className="flex justify-start text-md font-bold font-jamjuree"
            >
              {state.lang === "tha" ? data.name_t : data.name_e}
            </div>
            <span className="text-sm">
              [
              {state.lang === "tha"
                ? _.filter(Categories, ["cat_new", data.new_cate])[0].cat_tname
                : _.filter(Categories, ["cat_new", data.new_cate])[0].cat_ename}
              ]
            </span>
            {state.lang !== "tha" && (
              <div
                sr-only="admin_boundary"
                className="flex justify-start text-sm font-jamjuree"
              >
                <div className="text-left">
                  {data.address_e
                    ? data.address_e
                    : data.tambon_e +
                      ", " +
                      data.amphoe_e +
                      ", " +
                      data.province_e +
                      " " +
                      data.post_code}
                </div>
              </div>
            )}
            {state.lang === "tha" && (
              <div
                sr-only="admin_boundary"
                className="flex justify-start text-sm font-jamjuree"
              >
                <div className="text-left">
                  {data.address_t
                    ? data.address_t
                    : " ต." +
                      data.tambon_t +
                      " อ." +
                      data.amphoe_t +
                      " จ." +
                      data.province_t +
                      " " +
                      data.post_code}
                </div>
              </div>
            )}
            <div
              sr-only="admin_boundary"
              className="flex justify-start text-sm font-jamjuree"
            >
              <div className="flex text-left">
                {data.coordinates[0]}, {data.coordinates[1]}{" "}
                <button
                  className="flex"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      data.coordinates[0] + "," + data.coordinates[1]
                    );
                    return toast.info("Copy successfully", {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  <AiOutlineCopy className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          sr-only="dierction_icons"
          className="flex w-auto h-10 shadow-md p-2 justify-end items-center bg-blue-500 rounded-md"
        >
          <button
            onClick={() => {
              // console.log("data", data);
              handleRouting(data);
            }}
          >
            <MdAssistantDirection className="w-6 h-6 text-white" />
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default MapPopup;
