import React from "react";
import {
  RiCloseCircleLine,
  RiCloseFill,
  RiMapPin2Fill,
  RiMapPin2Line,
  RiMapPinAddLine,
} from "react-icons/ri";
import { AiOutlineClear, AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineArchitecture } from "react-icons/md";

const ContextMenu = ({ contextRef, onClick }) => {
  return (
    <div
      ref={contextRef}
      className="hidden absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="py-1 w-full" role="none">
        <div className="flex hover:bg-gray-200 border-b">
          <MdOutlineArchitecture className="text-green-500 m-auto ml-5 w-7 h-7" />
          <button
            name="Nearby POIs"
            onClick={onClick}
            className="flex text-gray-700 justify-start px-4 py-2 text-sm w-full hover:bg-gray-200 outline-none"
            role="menuitem"
            tabIndex="-1"
            id="menu-item-2"
          >
            Search Nearby
          </button>
        </div>
        <div className="flex hover:bg-gray-200 border-b">
          <AiOutlineClear className="text-red-500 m-auto ml-5 w-7 h-7" />
          <button
            name="Clear"
            onClick={onClick}
            className="flex text-gray-700 justify-start px-4 py-2 text-sm w-full hover:bg-gray-200 outline-none"
            role="menuitem"
            tabIndex="-1"
            id="menu-item-2"
          >
            Clear
          </button>
        </div>
        <div className="flex hover:bg-gray-200 ">
          <RiCloseCircleLine className="text-red-500 m-auto ml-5 w-7 h-7" />
          <button
            name="Close"
            onClick={onClick}
            className="flex text-gray-700 justify-start px-4 py-2 text-sm w-full hover:bg-gray-200 outline-none"
            role="menuitem"
            tabIndex="-1"
            id="menu-item-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
